import React from "react"
import { Link } from "gatsby"
import useBlogData from "../static_queries/useBlogData"
import blogListStyles from "../styles/components/bloglist.module.scss"
import Img from 'gatsby-image'

const bg = (blog, isProject) => (
    <li className={blogListStyles.li} key={blog.node.fields.slug} style={{marginBottom: '20px', padding: '0rem'}}>

      <div className={blogListStyles.list__info}>

        <p>{blog.node.frontmatter.title} (<Link to={`${blog.node.frontmatter.link}`} key={blog.node.id}>{blog.node.frontmatter.author}</Link>)</p>

        {(isProject || blog?.node?.frontmatter?.description?.length > 50) && (
        <p dangerouslySetInnerHTML={{__html: "- " + blog.node.frontmatter.description.replace(/\[([^\[]+)\](\(([^)]*))\)/gim, '<a href="$3">$1</a>')}}></p>)}

      {(blog?.node?.frontmatter?.title === "Sheriffs Offered Caribbean Cruises and Florida Retreats as Part of Jail Telecom Contracts") && (
        <p >- Included in The Appeal’s <a href="https://theappeal.org/the-appeal-most-impactful-reporting-2024/">"Most Impactful Reporting in 2024"</a></p>
      )}
      </div>
    </li>
)

export default function BlogList() {
  const blogData = useBlogData()

  const investigative = blogData.filter(blog => blog.node.frontmatter.type === 'investigative');
  const hyperlocal = blogData.filter(blog => blog.node.frontmatter.type === 'hyperlocal');
  const video = blogData.filter(blog => blog.node.frontmatter.type === 'video');
  const data = blogData.filter(blog => blog.node.frontmatter.type === 'data');
  const other = blogData.filter(blog => blog.node.frontmatter.type === 'other');
  const fiction = blogData.filter(blog => blog.node.frontmatter.type === 'fiction');


  function renderBlogData() {
    return (
      <div style={{padding: '1.5rem 1.25rem'}}>
        <div style={{ width: '100%'}}><strong >Investigations</strong></div>
        {investigative
          .map(blog => bg(blog))}

        <div style={{ width: '100%', marginTop: '2rem'  }}><strong>Metro Reporting</strong></div>
        {hyperlocal
          .map(blog => bg(blog))} 

        <div style={{ width: '100%', marginTop: '2rem'  }}><strong>Fiction</strong></div>
        {fiction
          .map(blog => bg(blog))}

        <div style={{ width: '100%', marginTop: '2rem'  }}><strong>Video</strong></div>
        {video
          .map(blog => bg(blog, false))}

        <div style={{ width: '100%', marginTop: '2rem'  }}><strong>Data</strong></div>
        {data
          .map(blog => bg(blog, true))}

          <div style={{ width: '100%', marginTop: '2rem'  }}><strong>Other</strong></div>
          {other
            .map(blog => bg(blog, false))}


        <div style={{ width: '100%', marginTop: '2rem'  }}><strong>Grants</strong>
        <div style={{ marginTop: '1rem'  }}>
          The Hearst Foundations - in support of study at CUNY Craig Newmark 2024-2025
          </div>
        </div>

      </div>

    )
  }

  return (
    <section>
      <ul className={blogListStyles.list}>{renderBlogData()}</ul>
    </section>
  )
}

